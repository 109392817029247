import { __assign } from "tslib";
import { createAction } from 'redux-actions';
import { getOpenPreviewPromptModalConfig } from '../../../components/preview-prompt/preview-prompt';
import { getCompId } from '../../../../widget/redux/client/hydrated-data/hydrated-data';
import { closeCatcher, openModal as _openModal, } from '../../../components/modal/utils';
export var OPEN_MODAL = 'CLIENT.OPEN_MODAL';
var openModalAction = createAction(OPEN_MODAL);
export var openModal = function (config) { return function (dispatch, getState) {
    var compId = getCompId(getState());
    var options = __assign(__assign({}, config), { compId: compId });
    dispatch(openModalAction(options));
    _openModal(options).catch(closeCatcher);
}; };
export var openPreviewPrompt = function () { return function (dispatch) {
    dispatch(openModal(getOpenPreviewPromptModalConfig()));
}; };
