import _ from 'lodash';
import { createSelector } from 'reselect';
import { getVideoIdFromAppSettings, isSingleVideo, } from '../../shared/selectors/app-settings';
import { getVideoIdFromUrl } from './query-params';
import { getVideosGroupedByIds } from '../../shared/selectors/videos';
import { dashify } from '@wix/wix-vod-shared/dist/src/common/utils/guid';
import { isGraphAPIEnabled } from '@wix/wix-vod-shared/dist/src/common/utils/experiments';
export var getVideoIdToSelect = createSelector([isSingleVideo, getVideoIdFromAppSettings, getVideoIdFromUrl], function (isSingleVideo, singleVideoId, videoIdFromUrl) {
    var videoIdToSelect = isSingleVideo ? singleVideoId : videoIdFromUrl;
    return isGraphAPIEnabled() ? dashify(videoIdToSelect) : videoIdToSelect;
});
export var videoToSelectExists = createSelector([getVideoIdToSelect, getVideosGroupedByIds], function (videoIdToSelect, videos) {
    return !_.isEmpty(videos[videoIdToSelect]);
});
