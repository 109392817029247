export default (function (biHandlers) { return function (_a) {
    var getState = _a.getState, dispatch = _a.dispatch;
    return function (next) { return function (action) {
        var previousState = getState();
        var returnValue = next(action); // eslint-disable-line callback-return
        var type = action.type;
        var handler = biHandlers[type];
        if (handler) {
            handler({
                dispatch: dispatch,
                state: getState(),
                previousState: previousState,
                action: action,
            });
        }
        return returnValue;
    }; };
}; });
