import { __read } from "tslib";
export var getBiToken = function (instance, platformAPIs) {
    try {
        var _a = __read(instance.split('.'), 2), data = _a[1];
        return JSON.parse(atob(data)).biToken;
    }
    catch (e) {
        return platformAPIs.bi.metaSiteId;
    }
};
