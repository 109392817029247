import { createSelector } from 'reselect';
import { URL_PARAM_COMP_ID, URL_PARAM_VIDEO_ID, } from '@wix/wix-vod-shared/dist/src/common/constants/url';
import { getCompId, getFullSiteUrl, } from '../redux/client/hydrated-data/hydrated-data';
import parseQueryParams from '../../shared/utils/get-query-params';
var getQueryParams = createSelector(getFullSiteUrl, parseQueryParams);
export var isCurrentComponentIdInUrl = createSelector(getQueryParams, getCompId, function (queryParams, compId) {
    return Boolean(compId && queryParams[URL_PARAM_COMP_ID] === compId);
});
export var getVideoIdFromUrl = createSelector(getQueryParams, isCurrentComponentIdInUrl, function (queryParams, isCompIdInUrl) {
    return isCompIdInUrl ? queryParams[URL_PARAM_VIDEO_ID] : null;
});
export var getUrlParam = createSelector(getQueryParams, isCurrentComponentIdInUrl, function (state, key) { return key; }, function (queryParams, isCompIdInUrl, key) {
    return isCompIdInUrl ? queryParams[key] : null;
});
