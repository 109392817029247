var _a;
import { __assign } from "tslib";
import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { NAMES as VIDEO_URLS_GET } from '../actions/video-urls/fetch';
import { NAMES as VIDEO_URLS_CLEAR } from '../actions/video-urls/clear';
import CACHE_ITEM_STATUS from '../../../constants/ulrs-cache-status';
var defaultState = {};
function setPending(state, _a) {
    var _b;
    var payload = _a.payload;
    var id = payload.params.videoItem.id;
    return __assign(__assign({}, state), (_b = {}, _b[id] = {
        status: CACHE_ITEM_STATUS.PENDING,
    }, _b));
}
function removeOnFail(state, _a) {
    var _b;
    var payload = _a.payload;
    var id = payload.params.videoItem.id;
    return __assign(__assign({}, state), (_b = {}, _b[id] = {
        status: CACHE_ITEM_STATUS.FAILED,
    }, _b));
}
function saveToCache(state, _a) {
    var _b;
    var payload = _a.payload;
    var urls = payload.response;
    var id = payload.params.videoItem.id;
    return __assign(__assign({}, state), (_b = {}, _b[id] = {
        status: CACHE_ITEM_STATUS.RESOLVED,
        urls: urls,
    }, _b));
}
function clearCacheForItem(state, _a) {
    var payload = _a.payload;
    var id = payload;
    return _.omit(state, id);
}
function clearCache() {
    return {};
}
export default handleActions((_a = {},
    _a[VIDEO_URLS_GET.START] = setPending,
    _a[VIDEO_URLS_GET.SUCCESS] = saveToCache,
    _a[VIDEO_URLS_GET.FAIL] = removeOnFail,
    _a[VIDEO_URLS_CLEAR.ALL] = clearCache,
    _a[VIDEO_URLS_CLEAR.FOR_ITEM] = clearCacheForItem,
    _a), defaultState);
