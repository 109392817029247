var _a;
import { __assign } from "tslib";
import { handleActions } from 'redux-actions';
import { SHARE_MODES } from '@wix/wix-vod-shared/dist/src/widget/vod-player/components/share-buttons/constants';
import { SHARE_OVERLAY_ACTIONS } from '../actions/share-overlay';
var DEFAULT_STATE = {
    isOpen: false,
    shareMode: null,
};
var toggleShareChannel = function (state) { return (__assign(__assign({}, state), { isOpen: !state.isOpen, shareMode: SHARE_MODES.CHANNEL })); };
var toggleShareVideo = function (state) { return (__assign(__assign({}, state), { isOpen: !state.isOpen, shareMode: SHARE_MODES.VIDEO })); };
export default handleActions((_a = {},
    _a[SHARE_OVERLAY_ACTIONS.TOGGLE_SHARE_CHANNEL] = toggleShareChannel,
    _a[SHARE_OVERLAY_ACTIONS.TOGGLE_SHARE_VIDEO] = toggleShareVideo,
    _a[SHARE_OVERLAY_ACTIONS.CLOSE] = function () { return DEFAULT_STATE; },
    _a), DEFAULT_STATE);
