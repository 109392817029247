import { combineReducers } from 'redux';
import plainStructure from 'connected-react-router/lib/structure/plain';
import createConnectRouter from 'connected-react-router/lib/reducer';
import slideMenuOpened from './reducers/slide-menu-opened';
import modules from './reducers/modules';
import videos from '../../widget/redux/client/reducers/videos';
import channels from '../../widget/redux/client/reducers/channels';
import channelVideos from '../../widget/redux/client/reducers/channel-videos';
import configuration from '../../widget/redux/client/reducers/configuration';
import appSettings from '../../widget/redux/client/reducers/app-settings';
import currentChannelId from '../../widget/redux/client/reducers/current-channel-id';
import search from '../../widget/redux/client/reducers/search';
import channelInfo from '../../widget/redux/client/reducers/channel-info';
import firstChannelVideoId from '../../widget/redux/client/reducers/first-channel-video-id';
import shareOverlay from '../../widget/redux/client/reducers/share-overlay';
import videoUrlsCache from '../../widget/redux/client/reducers/video-urls-cache';
import selectedVideoId from '../../widget/redux/client/reducers/selected-video-id';
import player from '../../widget/redux/client/reducers/player';
import isVideosTouched from '../../widget/redux/client/reducers/is-videos-touched';
import playback from '../../shared/redux/client/reducers/playback';
import videoCards from '../../widget/redux/client/reducers/video-cards/video-cards';
import payment from '../../widget/redux/client/reducers/payment';
import lazyChannelVideos from '../../widget/redux/client/lazy-channel-videos/reducer';
import windowSize from '../../widget/redux/client/reducers/window-size';
import isLightbox from '../../widget/redux/client/reducers/is-lightbox';
import fullScreenModal from '../../widget/redux/client/reducers/full-screen-modal';
import comments from '../../widget/redux/client/reducers/comments';
import { reducer as hydratedData } from '../../widget/redux/client/hydrated-data/hydrated-data';
var createWidgetReducers = function (_a) {
    var history = _a.history;
    return ({
        router: createConnectRouter(plainStructure)(history),
        slideMenuOpened: slideMenuOpened,
        modules: modules,
        search: search,
        entities: combineReducers({
            videos: videos,
            channels: channels,
        }),
        channelVideos: channelVideos,
        currentChannelId: currentChannelId,
        channelInfo: channelInfo,
        firstChannelVideoId: firstChannelVideoId,
        lazyChannelVideos: lazyChannelVideos,
        windowSize: windowSize,
        isLightbox: isLightbox,
        videoUrlsCache: videoUrlsCache,
        selectedVideoId: selectedVideoId,
        player: player,
        isVideosTouched: isVideosTouched,
        payment: payment,
        configuration: configuration,
        appSettings: appSettings,
        videoCards: videoCards,
        shareOverlay: shareOverlay,
        fullScreenModal: fullScreenModal,
        comments: comments,
        hydratedData: hydratedData,
        playback: playback,
    });
};
export default (function (_a) {
    var history = _a.history;
    return combineReducers(createWidgetReducers({ history: history }));
});
