export default {
    COMPONENT_DELETED: 'COMPONENT_DELETED',
    DEVICE_TYPE_CHANGED: 'DEVICE_TYPE_CHANGED',
    EDITOR_EVENT: 'EDITOR_EVENT',
    EDIT_MODE_CHANGE: 'EDIT_MODE_CHANGE',
    INSTANCE_CHANGED: 'INSTANCE_CHANGED',
    KEY_DOWN: 'KEY_DOWN',
    KEY_UP: 'KEY_UP',
    MEMBER_DETAILS_UPDATED: 'MEMBER_DETAILS_UPDATED',
    ON_MESSAGE_RESPONSE: 'ON_MESSAGE_RESPONSE',
    PAGE_NAVIGATION: 'PAGE_NAVIGATION',
    PAGE_NAVIGATION_CHANGE: 'PAGE_NAVIGATION_CHANGE',
    PAGE_NAVIGATION_IN: 'PAGE_NAVIGATION_IN',
    PAGE_NAVIGATION_OUT: 'PAGE_NAVIGATION_OUT',
    PUBLIC_DATA_CHANGED: 'PUBLIC_DATA_CHANGED',
    QUICK_ACTION_TRIGGERED: 'QUICK_ACTION_TRIGGERED',
    SCROLL: 'SCROLL',
    SESSION_CHANGED: 'SESSION_CHANGED',
    SETTINGS_UPDATED: 'SETTINGS_UPDATED',
    SITE_METADATA_CHANGED: 'SITE_METADATA_CHANGED',
    SITE_PUBLISHED: 'SITE_PUBLISHED',
    SITE_SAVED: 'SITE_SAVED',
    STATE_CHANGED: 'STATE_CHANGED',
    STYLE_PARAMS_CHANGE: 'STYLE_PARAMS_CHANGE',
    THEME_CHANGE: 'THEME_CHANGE',
    WINDOW_PLACEMENT_CHANGED: 'WINDOW_PLACEMENT_CHANGED',
};
