import _ from 'lodash';
var perf = global.vodPerformance || {
    now: _.noop,
    addVar: _.noop,
    addTimestamp: _.noop,
    stamps: {},
    vars: {},
};
var addVar = perf.addVar, addTimestamp = perf.addTimestamp, stamps = perf.stamps, vars = perf.vars, now = perf.now;
export var addSingleMeasurement = function (name, fromTimestamp) {
    if (fromTimestamp === void 0) { fromTimestamp = 'navigationStart'; }
    if (!stamps[name]) {
        addTimestamp(name, now());
    }
    if (!vars[name]) {
        addVar(name, now() - stamps[fromTimestamp]);
    }
};
export default perf;
