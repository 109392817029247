import { FedopsLoggerWrapper } from '@wix/wix-vod-shared/dist/src/fedops-logger/loggerWrapper';
import { InteractionsState } from '@wix/wix-vod-shared/dist/src/fedops-logger/interactions-state';
import { BASE_LOGGER, MOBILE_OVERLAY, MOBILE_WIDGET, DESKTOP_OVERLAY, DESKTOP_WIDGET, LOGGER_FNS, TABLET_WIDGET, } from '../actions/fedopsLogger';
export function createFedopsHandler(_a) {
    var _b;
    var loggerFactory = _a.loggerFactory, pubSub = _a.pubSub, compId = _a.compId, baseLoggerName = _a.baseLoggerName, isSlave = _a.isSlave;
    var interactionsState = new InteractionsState({ compId: compId, pubSub: pubSub });
    var createLogger = function (name) {
        var logger = loggerFactory(name);
        return new FedopsLoggerWrapper({ logger: logger, interactionsState: interactionsState });
    };
    var baseLogger = createLogger(baseLoggerName);
    var mobileOverlayLogger = createLogger('wix-vod-mobile-overlay');
    var mobileWidgetLogger = createLogger('wix-vod-mobile');
    var desktopOverlayLogger = createLogger('wix-vod-overlay');
    var desktopWidgetLogger = createLogger('wix-vod-widget');
    var tabletWidgetLogger = createLogger('wix-vod-tablet');
    isSlave
        ? interactionsState.requestInteractions()
        : interactionsState.subscribeOnInteractionsRequest();
    var loggersMapping = (_b = {},
        _b[BASE_LOGGER] = baseLogger,
        _b[MOBILE_OVERLAY] = mobileOverlayLogger,
        _b[MOBILE_WIDGET] = mobileWidgetLogger,
        _b[DESKTOP_OVERLAY] = desktopOverlayLogger,
        _b[DESKTOP_WIDGET] = desktopWidgetLogger,
        _b[TABLET_WIDGET] = tabletWidgetLogger,
        _b);
    return function (_a) {
        var loggerName = _a.loggerName, logFn = _a.logFn, interactionName = _a.interactionName;
        var logger = loggersMapping[loggerName];
        switch (logFn) {
            case LOGGER_FNS.APP_LOADED:
                if (!__OOI__) {
                    logger.appLoaded();
                }
                break;
            case LOGGER_FNS.INTERACTION_START:
                logger.interactionStarted(interactionName);
                break;
            case LOGGER_FNS.INTERACTION_END:
                logger.interactionEnded(interactionName);
                break;
            default:
                console.warn("unknown logFn: ".concat(logFn));
        }
    };
}
