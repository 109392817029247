var _a;
import { __assign } from "tslib";
import { handleActions } from 'redux-actions';
import { UPDATE_WINDOW_SIZE } from '../actions/window-size';
var defaultState = {
    width: 0,
    height: 0,
};
export default handleActions((_a = {},
    _a[UPDATE_WINDOW_SIZE] = function (state, _a) {
        var payload = _a.payload;
        return (__assign(__assign({}, state), payload));
    },
    _a), defaultState);
