import { createAction } from 'redux-actions';
import createAsyncActions from '../../../../shared/redux/helpers/create-async-actions';
export var CREATE_ORDER = createAsyncActions('PAYMENT.CREATE_ORDER');
export var INIT_CHECKOUT = createAsyncActions('PAYMENT.INIT_CHECKOUT');
export var SELECT_METHOD = createAction('PAYMENT.SELECT_METHOD');
export var statusChange = createAction('PAYMENT.STATUS_CHANGE');
export var setError = createAction('PAYMENT.ERROR');
export var STATUSES = {
    IDLE: 0,
    PENDING: 1,
    DONE: 2,
    FAIL: 3,
};
