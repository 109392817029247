export var DEFAULT_LOCALE = 'en';
export var INTL_LOCALE_OVERRIDES = {
    no: 'nb-NO',
};
export var AVAILABLE_LOCALES = process.env.AVAILABLE_LOCALES || [
    DEFAULT_LOCALE,
];
var _publicPath = process.env.PUBLIC_PATH;
export var SET_PUBLIC_PATH = function (pubPath) {
    _publicPath = pubPath;
};
export var GET_PUBLIC_PATH = function () { return _publicPath; };
