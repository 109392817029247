import _ from 'lodash';
/* eslint-disable max-len */
import { createSelector } from 'reselect';
import defaultSettings from '@wix/wix-vod-constants/dist/app-settings/default-settings';
import { defaultSettingsEditorX } from '@wix/wix-vod-constants/dist/app-settings/default-settings-editor-x';
import { CHANNEL_VIDEO_LIST_VALUES } from '@wix/wix-vod-constants/dist/app-settings/channel-video-list-values';
import { COMPACT_SHOW_VIDEO_LIST_VALUES } from '@wix/wix-vod-constants/dist/app-settings/compact-show-video-list';
import { PLAY_TYPE_VALUES } from '@wix/wix-vod-constants/dist/app-settings/compact-play-type';
import { MAIN_ACTION_STYLE_TYPES } from '@wix/wix-vod-constants/dist/app-settings/main-action-style-types';
import { LAYOUT_STYLE_VALUES } from '@wix/wix-vod-constants/dist/app-settings/layout-style-values';
import { CHANNEL_LAYOUT_VALUES, MOBILE_CHANNEL_LAYOUT_VALUES, } from '@wix/wix-vod-constants/dist/app-settings/channel-layout-values';
import { SHOW_INFO_VALUES } from '@wix/wix-vod-constants/dist/app-settings/show-info-values';
import { INFO_POSITION_VALUES } from '@wix/wix-vod-constants/dist/app-settings/info-position-values';
import { SHOW_EXPANDED_VIDEO_LIST_INFO_VALUES } from '@wix/wix-vod-constants/dist/app-settings/show-expanded-video-list-titles';
import { LOAD_MORE_BUTTON_TYPE_VALUES } from '@wix/wix-vod-constants/dist/app-settings/load-more-button-type-values';
import { NAVIGATION_ARROWS_BEHAVIOR } from '@wix/wix-vod-constants/dist/app-settings/navigation-arrows-behavior';
import { NAVIGATION_ARROWS_POSITION } from '@wix/wix-vod-constants/dist/app-settings/navigation-arrows-position';
import { THUMBNAIL_BUTTONS_BEHAVIOR } from '@wix/wix-vod-constants/dist/app-settings/thumbnail-buttons-behavior';
import { PREVIEW_TYPES } from '@wix/wix-vod-constants/dist/app-settings/preview-types';
import { MAIN_ITEM_TYPE_VALUES } from '@wix/wix-vod-constants/dist/app-settings/main-item-type-values';
import { RTL_LOCALES } from '@wix/wix-vod-constants/dist/common/rtl-locales';
import { getChannelId, getVideoId } from '../utils/app-settings';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { isSubscribedToChannel } from '@wix/wix-vod-shared/dist/src/common/utils/overlay-buttons-utils';
export var getAllSettings = function (state) { return state.appSettings; };
export var isResponsiveEditor = createSelector(getAllSettings, function (settings) { return settings.booleans.responsive; });
var createSettingGetter = function (settingPath, fallbackValue) { return function (state) {
    var defaults = isResponsiveEditor(state)
        ? defaultSettingsEditorX
        : defaultSettings;
    return _.get(state, settingPath, fallbackValue || _.get(defaults, settingPath.replace('appSettings.', '')));
}; };
export var getLocale = createSettingGetter('appSettings.locale');
export var getAutoplay = createSettingGetter('appSettings.booleans.autoPlay');
export var getNumberOfRows = createSettingGetter('appSettings.numbers.numberOfRows');
export var getStretchToFullWidth = createSettingGetter('appSettings.booleans.stretchToFullWidth');
export var getVideosInRowCount = createSettingGetter('appSettings.numbers.videosInRow');
export var getVideoInfoPosition = createSettingGetter('appSettings.numbers.videoInfoPosition');
export var getInfoActionVisibility = createSettingGetter('appSettings.numbers.showInfoAction');
export var getExpandedViewInfoActionVisibility = createSettingGetter('appSettings.numbers.showInfoAction');
export var getLayoutStyle = createSettingGetter('appSettings.numbers.layoutStyle');
export var getExpandedViewTheme = createSettingGetter('appSettings.numbers.expandedTheme');
export var getPlayType = createSettingGetter('appSettings.numbers.playType');
export var getCompactMainActionStyleType = createSettingGetter('appSettings.numbers.mainActionStyleType');
export var getChannelLayout = createSettingGetter('appSettings.numbers.channelLayout');
export var getMobileChannelLayout = createSettingGetter('appSettings.numbers.mobileChannelLayout');
export var getMainItemOverlayOpacity = createSettingGetter('appSettings.numbers.mainItemOverlayOpacity');
export var getThumbnailOverlayOpacity = createSettingGetter('appSettings.numbers.thumbnailOverlayOpacity');
export var getVideoListVisibility = createSettingGetter('appSettings.numbers.videoListVisibility');
var createVideoListVisibilitySelector = function (expectedMode) {
    return createSelector(getVideoListVisibility, function (mode) { return mode === expectedMode; });
};
export var isVideoListAlwaysShow = createVideoListVisibilitySelector(CHANNEL_VIDEO_LIST_VALUES.ALWAYS_SHOW);
export var isVideoListExpandOnClick = createVideoListVisibilitySelector(CHANNEL_VIDEO_LIST_VALUES.EXPAND_ON_CLICK);
export var isVideoListNeverShow = createVideoListVisibilitySelector(CHANNEL_VIDEO_LIST_VALUES.NEVER_SHOW);
export var getCompactShowVideoListType = createSettingGetter('appSettings.numbers.compactShowVideoListType');
export var getShowSearch = createSettingGetter('appSettings.booleans.showSearch');
export var getThumbnailSpacing = createSettingGetter('appSettings.numbers.thumbnailSpacing');
export var getSliderHeight = createSettingGetter('appSettings.numbers.sliderHeight');
export var getTextAlignment = createSettingGetter('appSettings.numbers.textAlignment');
export var getUseOptimalFontSizes = createSettingGetter('appSettings.booleans.useOptimalFontSizes');
export var getTitleFont = createSettingGetter('appSettings.fonts.titleFont');
export var getTextFont = createSettingGetter('appSettings.fonts.textFont');
export var getThumbnailOverlayColor = createSettingGetter('appSettings.colors.thumbnailOverlayColor');
export var getLoadMoreButtonType = createSettingGetter('appSettings.numbers.loadMoreButtonType');
export var getVideoEndOption = createSettingGetter('appSettings.numbers.videoEndOption');
export var isSingleVideo = createSelector(getAllSettings, function (settings) { return !_.isEmpty(getVideoId(settings)); });
export var isLoadMoreDisplayedAsLink = createSelector(getLoadMoreButtonType, function (type) { return type === LOAD_MORE_BUTTON_TYPE_VALUES.TEXT; });
var createIsLayoutSelector = function (layoutType) {
    return createSelector(getChannelLayout, function (channelLayout) { return channelLayout === layoutType; });
};
export var isRTL = createSelector(getLocale, function (locale) { return locale && RTL_LOCALES.indexOf(locale) !== -1; });
export var isVerticalLayoutMobile = createSelector(getMobileChannelLayout, function (mobileChannelLayout) {
    return mobileChannelLayout === MOBILE_CHANNEL_LAYOUT_VALUES.VERTICAL;
});
export var isCompactLayout = createIsLayoutSelector(CHANNEL_LAYOUT_VALUES.COMPACT);
export var isStripLayout = createIsLayoutSelector(CHANNEL_LAYOUT_VALUES.STRIP);
export var isClassicLayout = createIsLayoutSelector(CHANNEL_LAYOUT_VALUES.CLASSIC);
export var isGridLayout = createIsLayoutSelector(CHANNEL_LAYOUT_VALUES.GRID);
export var isSliderLayout = createSelector(getChannelLayout, function (channelLayout) {
    return channelLayout === CHANNEL_LAYOUT_VALUES.SLIDER_BIG ||
        channelLayout === CHANNEL_LAYOUT_VALUES.SLIDER_SMALL;
});
export var canShowSearch = createSelector(getShowSearch, isCompactLayout, function (isSearchVisible, isCompact) { return isSearchVisible && !isCompact; });
export var canShowMainItemTitle = createSettingGetter('appSettings.booleans.showTitleForMainItem');
export var getCanShowChannelInfo = createSettingGetter('appSettings.booleans.showChannelInfo');
export var getCanShowChannelShare = createSettingGetter('appSettings.booleans.showChannelShare');
export var getCanShowSignInBySetting = createSettingGetter('appSettings.booleans.showSignIn');
export var getCanShowSignIn = createSelector(getCanShowSignInBySetting, getChannelForWidget, function (canShow, channelData) { return canShow || isSubscribedToChannel(channelData); });
export var canShowChannelShare = createSelector(getCanShowChannelShare, isSingleVideo, function (isChannelShareVisible, isSingleVideo) {
    if (isSingleVideo) {
        return false;
    }
    return isChannelShareVisible;
});
export var canShowVideoList = createSelector(getCompactShowVideoListType, function (compactShowVideoListType) {
    return compactShowVideoListType !== COMPACT_SHOW_VIDEO_LIST_VALUES.NEVER_SHOW;
});
export var canShowActionBar = createSelector(canShowVideoList, getCanShowChannelShare, getCanShowChannelInfo, getCanShowSignIn, isSingleVideo, function (showVideoList, showChannelShare, showChannelInfo, showSignIn, isSingleVideo) {
    return !isSingleVideo &&
        (showVideoList || showChannelShare || showChannelInfo || showSignIn);
});
export var canShowVideoListStrip = createSelector(getVideoListVisibility, function (videoListVisibility) {
    return videoListVisibility !== CHANNEL_VIDEO_LIST_VALUES.NEVER_SHOW;
});
export var getShowPublisherForMainItem = createSettingGetter('appSettings.booleans.showPublisherForMainItem');
export var canShowMainItemPublisher = createSelector(getShowPublisherForMainItem, isCompactLayout, function (isPublisherVisible, isCompact) { return isPublisherVisible && !isCompact; });
var getPreviewType = createSettingGetter('appSettings.numbers.previewType');
var createIsPreviewOfTypeSelector = function (type) {
    return createSelector(getPreviewType, function (previewType) { return previewType === type; });
};
export var isThumbnailsPreviewHover = createIsPreviewOfTypeSelector(PREVIEW_TYPES.THUMBNAILS_HOVER);
export var isMenuPreview = createIsPreviewOfTypeSelector(PREVIEW_TYPES.MENU);
export var isActionBarPreview = createIsPreviewOfTypeSelector(PREVIEW_TYPES.ACTION_BAR);
export var isNavigationArrowsPreview = createIsPreviewOfTypeSelector(PREVIEW_TYPES.NAVIGATION_ARROWS);
export var getNavigationArrowsBehavior = createSettingGetter('appSettings.numbers.navigationArrowsBehavior');
export var getThumbnailButtonsBehavior = createSettingGetter('appSettings.numbers.thumbnailButtonsBehavior');
export var isSliderNavAlwaysVisible = createSelector(getNavigationArrowsBehavior, isNavigationArrowsPreview, function (behaviorType, isPreview) {
    return behaviorType === NAVIGATION_ARROWS_BEHAVIOR.ALWAYS || isPreview;
});
export var isButtonsShownOnHover = createSelector(getThumbnailButtonsBehavior, isThumbnailsPreviewHover, function (behaviorType, isPreview) {
    return behaviorType === THUMBNAIL_BUTTONS_BEHAVIOR.ON_HOVER && !isPreview;
});
export var getNavigationArrowsPosition = createSettingGetter('appSettings.numbers.navigationArrowsPosition');
export var isNavigationArrowsOutside = createSelector(getNavigationArrowsPosition, function (position) { return position === NAVIGATION_ARROWS_POSITION.OUTSIDE; });
export var getVideoListPublisherVisibility = createSettingGetter('appSettings.booleans.showVideoPublisherInList');
export var canShowVideoListItemPublisher = createSelector(getVideoListPublisherVisibility, isCompactLayout, function (isPublisherVisible, isCompact) { return isPublisherVisible && !isCompact; });
export var getChannelIdFromAppSettings = createSelector(getAllSettings, function (settings) { return getChannelId(settings) || getChannelId(defaultSettings); });
export var getVideoIdFromAppSettings = createSelector(getAllSettings, function (settings) { return getVideoId(settings) || getVideoId(defaultSettings); });
export var isCommentsEnabled = createSettingGetter('appSettings.booleans.allowComments');
export var isCommentsEnabledForLive = createSettingGetter('appSettings.booleans.allowCommentsLive', true);
export var canShowVideoListItemTitle = createSettingGetter('appSettings.booleans.showVideoTitleInList');
export var canShowVideoListItemDuration = createSettingGetter('appSettings.booleans.showVideoDurationInList');
export var canShowVideoListItemDescription = createSettingGetter('appSettings.booleans.showVideoDescriptionInList');
export var canShowVideoListItemContentBelow = createSelector(canShowVideoListItemTitle, canShowVideoListItemPublisher, canShowVideoListItemDescription, function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return _.some(args);
});
export var canShowFullInfo = createSettingGetter('appSettings.booleans.showFullInfo');
export var getCanShareVideo = createSettingGetter('appSettings.booleans.enableVideosSharing');
export var getCanShowChannelTags = createSettingGetter('appSettings.booleans.showChannelTags');
export var canShowChannelTags = createSelector(canShowSearch, getCanShowChannelTags, function (canShowSearch, canShowTags) { return canShowSearch && canShowTags; });
export var canShowExpandedVideoInfo = createSettingGetter('appSettings.booleans.showExpandedVideoInfo');
export var canShowExpandedVideoShare = createSettingGetter('appSettings.booleans.enableExpandedVideosSharing');
export var canShowExpandedSignIn = createSelector(createSettingGetter('appSettings.booleans.showExpandedSignIn'), getCanShowSignIn, isSliderLayout, isGridLayout, function (isExpandedSignInVisible, isSignInVisible, isSlider, isGrid) {
    return isSlider || isGrid ? isExpandedSignInVisible : isSignInVisible;
});
export var canShowExpandedChannelTitle = createSettingGetter('appSettings.booleans.showExpandedChannelTitle');
export var canShowExpandedVideoTitle = createSettingGetter('appSettings.booleans.showExpandedVideoTitle');
export var canShowExpandedVideoList = createSettingGetter('appSettings.booleans.showExpandedVideoList');
export var canShowChannelTitle = createSettingGetter('appSettings.booleans.showChannelTitle');
export var canShowChannelCategories = createSettingGetter('appSettings.booleans.showChannelCategories');
export var getMainItemType = createSettingGetter('appSettings.numbers.mainItemType');
export var getContainerMargins = createSettingGetter('appSettings.numbers.containerMargins');
export var isMainItemChannelCover = createSelector(getMainItemType, function (mainItemType) { return mainItemType === MAIN_ITEM_TYPE_VALUES.CHANNEL_COVER; });
export var areElementsRounded = createSelector(getLayoutStyle, function (layoutStyle) { return layoutStyle === LAYOUT_STYLE_VALUES.ROUND; });
export var isPlayInFrame = createSelector(getPlayType, function (playType) { return playType === PLAY_TYPE_VALUES.IN_FRAME; });
export var isCompactMainActionsOnlyIcons = createSelector(getCompactMainActionStyleType, function (styleType) { return styleType === MAIN_ACTION_STYLE_TYPES.ICON; });
export var isButtonsTextShown = createSelector(getCompactMainActionStyleType, function (styleType) { return styleType === MAIN_ACTION_STYLE_TYPES.ICON_WITH_TEXT; });
export var isVideosListInfoAlwaysShown = createSelector(getInfoActionVisibility, function (visibilityType) { return visibilityType === SHOW_INFO_VALUES.ALWAYS; });
export var isVideosListInfoNeverShown = createSelector(getInfoActionVisibility, function (visibilityType) { return visibilityType === SHOW_INFO_VALUES.NEVER; });
export var isVideosListInfoPositionBelow = createSelector(getVideoInfoPosition, function (videoInfoPosition) { return videoInfoPosition === INFO_POSITION_VALUES.BELOW_VIDEOS; });
export var isVideosListInfoShownOnHover = createSelector(getInfoActionVisibility, function (visibilityType) { return visibilityType === SHOW_INFO_VALUES.ON_MOUSE_OVER; });
export var isExpandedModeVideosListInfoAlwaysShown = createSelector(getExpandedViewInfoActionVisibility, function (visibilityType) {
    return visibilityType === SHOW_EXPANDED_VIDEO_LIST_INFO_VALUES.ALWAYS;
});
export var isExpandedModeVideosListInfoShownOnHover = createSelector(getExpandedViewInfoActionVisibility, function (visibilityType) {
    return visibilityType === SHOW_EXPANDED_VIDEO_LIST_INFO_VALUES.ON_MOUSE_OVER;
});
