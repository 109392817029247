import { combineReducers } from 'redux';
import playback from './playback';
import state from './state';
import ui from './ui';
import savedTime from './saved-time';
export default combineReducers({
    playback: playback,
    state: state,
    ui: ui,
    savedTime: savedTime,
});
