var _a;
import { handleActions } from 'redux-actions';
import { SELECTED_VIDEO_ACTIONS } from '../actions/select-video';
import { NAMES as VIDEO_PLAYBACK_ACTIONS } from '../actions/player/change-playback-status';
function resetSelectedVideoID() {
    return null;
}
function setSelectedVideoID(state, _a) {
    var payload = _a.payload;
    return payload || null;
}
function setVideoIdFromPlayAction(state, _a) {
    var payload = _a.payload;
    return payload || state;
}
export default handleActions((_a = {},
    _a[SELECTED_VIDEO_ACTIONS.SET] = setSelectedVideoID,
    _a[SELECTED_VIDEO_ACTIONS.RESET] = resetSelectedVideoID,
    _a[VIDEO_PLAYBACK_ACTIONS.REQUEST_PLAY] = setVideoIdFromPlayAction,
    _a), null);
