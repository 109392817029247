import { combineReducers } from 'redux';
import plainStructure from 'connected-react-router/lib/structure/plain';
import createConnectRouter from 'connected-react-router/lib/reducer';
import videos from './client/reducers/videos';
import channels from './client/reducers/channels';
import channelVideos from './client/reducers/channel-videos';
import configuration from './client/reducers/configuration';
import appSettings from './client/reducers/app-settings';
import currentChannelId from './client/reducers/current-channel-id';
import search from './client/reducers/search';
import channelInfo from './client/reducers/channel-info';
import firstChannelVideoId from './client/reducers/first-channel-video-id';
import shareOverlay from './client/reducers/share-overlay';
import videoUrlsCache from './client/reducers/video-urls-cache';
import selectedVideoId from './client/reducers/selected-video-id';
import player from './client/reducers/player';
import isVideosTouched from './client/reducers/is-videos-touched';
import videoCards from './client/reducers/video-cards/video-cards';
import payment from './client/reducers/payment';
import lazyChannelVideos from './client/lazy-channel-videos/reducer';
import windowSize from './client/reducers/window-size';
import isLightbox from './client/reducers/is-lightbox';
import fullScreenModal from './client/reducers/full-screen-modal';
import comments from './client/reducers/comments';
import { reducer as hydratedData } from './client/hydrated-data/hydrated-data';
import modules from './client/reducers/modules';
import playback from '../../shared/redux/client/reducers/playback';
var createWidgetReducers = function (_a) {
    var history = _a.history;
    return ({
        router: createConnectRouter(plainStructure)(history),
        search: search,
        entities: combineReducers({
            videos: videos,
            channels: channels,
        }),
        channelVideos: channelVideos,
        currentChannelId: currentChannelId,
        channelInfo: channelInfo,
        firstChannelVideoId: firstChannelVideoId,
        lazyChannelVideos: lazyChannelVideos,
        windowSize: windowSize,
        isLightbox: isLightbox,
        videoUrlsCache: videoUrlsCache,
        selectedVideoId: selectedVideoId,
        player: player,
        isVideosTouched: isVideosTouched,
        payment: payment,
        configuration: configuration,
        appSettings: appSettings,
        videoCards: videoCards,
        shareOverlay: shareOverlay,
        fullScreenModal: fullScreenModal,
        comments: comments,
        hydratedData: hydratedData,
        modules: modules,
        playback: playback,
    });
};
export default (function (_a) {
    var history = _a.history;
    return combineReducers(createWidgetReducers({ history: history }));
});
