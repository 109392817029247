import { __awaiter, __generator } from "tslib";
import { videoToSelectExists, getVideoIdToSelect, } from '../selectors/video-id-to-select';
import { selectVideo } from './client/actions/select-video';
import { getPublicVideo } from './server/actions/channel/video/get';
import { getPublicChannel } from './server/actions/channel/get-public';
import { getPaymentInfo } from './server/actions/channel/payment';
import { loadMoreVideoPages, prependVideoIds, } from './client/lazy-channel-videos/actions';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { clearHydratedData } from './client/hydrated-data/hydrated-data';
import { getVideoById } from '../../shared/selectors/videos';
import { getCurrentChannelId } from '../selectors/channel-info';
var getVideo = function () { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var state, videoId, videoFetched, currentChannelId;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                state = getState();
                videoId = getVideoIdToSelect(state);
                if (!videoId) {
                    return [2 /*return*/];
                }
                videoFetched = Boolean(getVideoById(state, videoId));
                if (videoFetched) {
                    return [2 /*return*/];
                }
                currentChannelId = getCurrentChannelId(state);
                return [4 /*yield*/, dispatch(getPublicVideo(currentChannelId, videoId))];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); }; };
var selectVideoIfNeeded = function () { return function (dispatch, getState) {
    var state = getState();
    if (videoToSelectExists(state)) {
        // TODO: this might be useless already, video should be loaded 100%
        var videoIdToSelect = getVideoIdToSelect(state);
        dispatch(selectVideo(videoIdToSelect));
    }
}; };
export var setInitialState = function (isSSR) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var state, currentChannelId, channelData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                state = getState();
                currentChannelId = getCurrentChannelId(state);
                return [4 /*yield*/, Promise.all([
                        getPublicChannel(currentChannelId),
                        loadMoreVideoPages(),
                        getPaymentInfo(currentChannelId, isSSR),
                        getVideo(),
                    ].map(function (action) { return dispatch(action); }))];
            case 1:
                _a.sent();
                dispatch(clearHydratedData());
                channelData = getChannelForWidget(getState());
                if (channelData.featuredItem) {
                    dispatch(prependVideoIds(channelData.featuredItem));
                }
                dispatch(selectVideoIfNeeded());
                return [2 /*return*/];
        }
    });
}); }; };
export var updateWidgetSingleVideo = function () { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch(getVideo())];
            case 1:
                _a.sent();
                dispatch(selectVideoIfNeeded());
                return [2 /*return*/];
        }
    });
}); }; };
