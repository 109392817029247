import _ from 'lodash';
import { parseCatalogChannel } from './catalog';
import { parseYoutubeChannel } from '@wix/wix-vod-api/dist/src/public/youtube/parsers/channel';
import { mergeResponse } from './utils';
export var parseChannel = function (channel) {
    var youtubeData = channel.youtube_data;
    var parsedChannel = parseCatalogChannel(_.omit(channel, 'youtube_data'));
    if (youtubeData) {
        var parsedYoutubeItem = parseYoutubeChannel(parsedChannel.urlType, youtubeData);
        return mergeResponse(parsedChannel, parsedYoutubeItem);
    }
    return parsedChannel;
};
