import { __rest } from "tslib";
import _ from 'lodash';
import { createAction } from 'redux-actions';
export var DEFAULT_NAMES = ['START', 'SUCCESS', 'FAIL'];
function toPayload(params, response) {
    if (params === void 0) { params = {}; }
    if (response === void 0) { response = null; }
    var payload = { params: params };
    if (response) {
        payload.response = response;
    }
    return payload;
}
function toMeta(params, response, meta) {
    if (meta === void 0) { meta = null; }
    if (meta && _.isArray(meta.analytics)) {
        return meta;
    }
    if (meta && meta.analytics && !meta.analytics.payload) {
        var _a = meta.analytics, type = _a.type, payload = __rest(_a, ["type"]);
        meta.analytics = { type: type, payload: payload };
    }
    return meta;
}
export default function (namespace, actionsNames) {
    if (actionsNames === void 0) { actionsNames = DEFAULT_NAMES; }
    var NAMES = _.reduce(actionsNames, function (res, name) {
        res[name] = "".concat(namespace, ".").concat(name);
        return res;
    }, {});
    return _.reduce(actionsNames, function (res, name) {
        res[name] = createAction(NAMES[name], toPayload, toMeta);
        return res;
    }, { NAMES: NAMES });
}
