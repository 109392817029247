"use strict";
var counter = 0;
function getOrder() {
    return ++counter;
}
var subscribers = [];
var initialStyles = [];
function addInitialStyle(st) {
    initialStyles.push(st);
    subscribers.forEach(function (sub) {
        sub(st);
    });
}
function subscribeGlobalStyles(cb) {
    if (!__SERVER__) {
        subscribers.push(cb);
    }
    initialStyles.forEach(function (st) {
        cb(st);
    });
}
module.exports = { getOrder: getOrder, addInitialStyle: addInitialStyle, subscribeGlobalStyles: subscribeGlobalStyles };
