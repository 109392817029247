import { __read, __spreadArray } from "tslib";
import _ from 'lodash';
import { createSelector } from 'reselect';
import * as MOBILE_VIDEOS_PER_PAGE_COUNT from '@wix/wix-vod-constants/dist/mobile/videos-per-page-count';
import { getAllSettings, getNumberOfRows, isStripLayout, isClassicLayout, getVideosInRowCount, isCompactLayout, isGridLayout, isVerticalLayoutMobile, isSliderLayout, } from '../../../../shared/selectors/app-settings';
import { COMPACT_NUMBER_OF_ROWS, COMPACT_VIDEOS_IN_ROW, } from '@wix/wix-vod-constants/dist/app-settings/compact-video-counts';
import { VIDEO_LIST_ITEM_WIDTH } from '../../../../shared/constants/lightbox';
import { getMaxVideosCountInRow as getGridVideosPerRow } from '../../../layouts/grid/selectors';
import { getVideosGroupedByIds } from '../../../../shared/selectors/videos';
import { isMobile } from '../../../../shared/selectors/form-factor';
var DEFAULT_VIDEOS_COUNT = 8;
export var getVideosPerPageCount = createSelector(getAllSettings, getNumberOfRows, getVideosInRowCount, getGridVideosPerRow, isStripLayout, isClassicLayout, isCompactLayout, isGridLayout, isVerticalLayoutMobile, function (state) { return state.isLightbox; }, isMobile, isSliderLayout, function (appSettings, numberOfRows, videosInRow, gridVideosInRow, isStrip, isClassic, isCompact, isGrid, isGridMobile, isLightbox, isMobile, isSlider) {
    if (!appSettings) {
        return 0;
    }
    if (isMobile) {
        return isGridMobile
            ? MOBILE_VIDEOS_PER_PAGE_COUNT.VERTICAL_LAYOUT
            : MOBILE_VIDEOS_PER_PAGE_COUNT.BASIC_LAYOUT;
    }
    if (isLightbox) {
        return Math.ceil(window.innerWidth / VIDEO_LIST_ITEM_WIDTH);
    }
    if (isGrid) {
        return gridVideosInRow * numberOfRows;
    }
    if (isCompact) {
        return COMPACT_NUMBER_OF_ROWS * COMPACT_VIDEOS_IN_ROW;
    }
    if (isStrip) {
        return videosInRow * numberOfRows;
    }
    if (isClassic) {
        return videosInRow * numberOfRows;
    }
    if (isSlider) {
        return DEFAULT_VIDEOS_COUNT;
    }
    return DEFAULT_VIDEOS_COUNT;
});
export var getListVideoIds = function (state) { return state.lazyChannelVideos.ids; };
export var getPrependedVideoIds = function (state) {
    return state.lazyChannelVideos.prependedIds;
};
export var getVideoIds = createSelector(getPrependedVideoIds, getListVideoIds, function (prependedIds, ids) { return __spreadArray(__spreadArray([], __read(prependedIds), false), __read(_.without.apply(_, __spreadArray([ids], __read(prependedIds), false))), false); });
export var getChannelVideos = createSelector(getVideoIds, getVideosGroupedByIds, function (channelVideoIds, allVideos) { return _.map(channelVideoIds, function (id) { return allVideos[id]; }); });
export var getCursor = function (state) { return state.lazyChannelVideos.cursor; };
export var getCurrentPageIndex = function (state) {
    return state.lazyChannelVideos.currentPageIndex;
};
export var getCurrentVideoIndex = function (state) {
    return state.lazyChannelVideos.currentVideoIndex;
};
export var getIsFetching = function (state) { return state.lazyChannelVideos.isFetching; };
export var getVideoIdsByPageNumber = createSelector(getVideoIds, getVideosPerPageCount, function (ids, videosPerPage) { return _.chunk(ids, videosPerPage); });
export var hasPrevVideo = createSelector(getVideoIds, getCurrentVideoIndex, function (ids, index) { return Boolean(ids[index - 1]) && index !== -1; });
export var hasNextVideo = createSelector(getVideoIds, getCurrentVideoIndex, function (ids, index) { return Boolean(ids[index + 1]) && index !== -1; });
export var getNextVideoId = createSelector(getVideoIds, getCurrentVideoIndex, function (ids, index) { return ids[index + 1] || null; });
