import { __awaiter, __generator } from "tslib";
import { noop } from 'lodash';
import { createWorkerHandler } from './createHandler';
import { createFedopsHandler } from './handlers/fedopsLogger';
import { createPerformanceHandler } from './handlers/performanceLogger';
import { create } from '@wix/fedops-logger';
export var createViewerScriptConfigForOOI = {
    createWorkerHandlerFromConfig: function (controllerConfig, _a) {
        var _this = this;
        var performanceLogger = _a.performanceLogger, isSlave = _a.isSlave;
        var compId = controllerConfig.compId, platformAPIs = controllerConfig.platformAPIs, wixCodeApi = controllerConfig.wixCodeApi;
        var pubSub = platformAPIs.pubSub;
        var fedopsHandler = createFedopsHandler({
            loggerFactory: create,
            pubSub: pubSub,
            compId: compId,
            baseLoggerName: 'wix-vod-widget',
            isSlave: isSlave,
        });
        var setNewDimensions = function (width, height) {
            return controllerConfig.setProps({
                dimensions: {
                    width: width,
                    height: height,
                },
            });
        };
        return createWorkerHandler(controllerConfig, {
            performanceApplicationLoadedHandler: createPerformanceHandler({
                performanceLogger: performanceLogger,
            }),
            fedopsHandler: fedopsHandler,
            handleSetWidgetHeight: function (height, width) { return setNewDimensions(width, height); },
            handleFitIntoView: noop,
            handleResizeWindow: setNewDimensions,
            handleResizeComponent: setNewDimensions,
            handleLogin: function (_a, onLogin, onError) {
                var mode = _a.mode, language = _a.language;
                return __awaiter(_this, void 0, void 0, function () {
                    var user, _b, error_1;
                    var _c;
                    return __generator(this, function (_d) {
                        switch (_d.label) {
                            case 0:
                                _d.trys.push([0, 3, , 4]);
                                return [4 /*yield*/, wixCodeApi.user.promptLogin({ mode: mode, language: language })];
                            case 1:
                                user = _d.sent();
                                _b = onLogin;
                                _c = {
                                    id: user.id
                                };
                                return [4 /*yield*/, wixCodeApi.user.currentUser.getEmail()];
                            case 2:
                                _b.apply(void 0, [(_c.email = _d.sent(),
                                        _c)]);
                                return [3 /*break*/, 4];
                            case 3:
                                error_1 = _d.sent();
                                onError(error_1);
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                });
            },
            handleLogout: wixCodeApi.user.logout,
            handleGoToPricingPlanPicker: function () { },
        });
    },
};
