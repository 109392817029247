import { __assign, __read, __spreadArray } from "tslib";
import Lodash from 'lodash';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import biMiddleware from './bi/bi-middleware';
import lazyChannelVideosMiddleware from '../widget/redux/middleware/lazy-channel-videos/lazy-channel-videos-middleware';
import biHandlers from '../widget/redux/middleware/bi-middleware/bi-handlers';
import { initNotForPreview } from './utils/not-for-preview';
export var createConfigureStore = function (_a) {
    var createRootReducer = _a.createRootReducer, createRootReducerPath = _a.createRootReducerPath;
    return function (_a) {
        var _b = _a.initialState, initialState = _b === void 0 ? {} : _b, _c = _a.middlewares, additionalMiddlewares = _c === void 0 ? [] : _c, history = _a.history;
        var storage = {};
        var middlewares = [
            thunk.withExtraArgument({ storage: storage }),
            routerMiddleware(history),
            lazyChannelVideosMiddleware,
            biMiddleware(biHandlers),
        ];
        if (__DEBUG__ && !__SERVER__) {
            var _d = require('redux-logger'), createLogger = _d.createLogger, defaults = _d.defaults;
            var options = __assign(__assign({}, defaults), { collapsed: true, diff: true });
            var timestamp_1 = options.timestamp, duration_1 = options.duration;
            var reduxLogger = createLogger(__assign(__assign({}, options), { titleFormatter: function (action, time, took) {
                    var parts = ['👮🏿'];
                    if (action.type.includes('.FAIL')) {
                        parts[0] = '👏🏽';
                        console.error("ACTION FAILED: ".concat(action.type));
                    }
                    parts.push(action.type);
                    if (timestamp_1) {
                        parts.push(time);
                    }
                    if (duration_1) {
                        parts.push("(in ".concat(took.toFixed(2), " ms)"));
                    }
                    return parts.join(' ');
                } }));
            middlewares.push(reduxLogger);
        }
        middlewares.push.apply(middlewares, __spreadArray([], __read(additionalMiddlewares), false));
        var middleware = !__SERVER__
            ? require('redux-devtools-extension').composeWithDevTools(applyMiddleware.apply(void 0, __spreadArray([], __read(middlewares), false)))
            : applyMiddleware.apply(void 0, __spreadArray([], __read(middlewares), false));
        // Create final store and subscribe router in debug env ie. for devtools
        var store = middleware(createStore)(createRootReducer({ history: history }), Lodash.omit(initialState, 'router'));
        if (module.hot) {
            module.hot.accept(createRootReducerPath, function () {
                var createNextRootReducer = require(createRootReducerPath).default;
                store.replaceReducer(createNextRootReducer({ history: history }));
            });
        }
        initNotForPreview(store);
        return store;
    };
};
