import { __awaiter, __generator } from "tslib";
import { PricingPlansAPI } from '@wix/wix-vod-pricing-plans-api';
import { HttpClient } from '@wix/http-client';
export var createGoToPricingPlanPickerHandler = function (handleGoToPricingPlanPicker, instance) {
    return function (channelId) { return __awaiter(void 0, void 0, void 0, function () {
        var httpClient, pricingPlansAPI, plansIds;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    httpClient = new HttpClient({
                        getAppToken: function () { return instance; },
                    });
                    pricingPlansAPI = new PricingPlansAPI(httpClient);
                    return [4 /*yield*/, pricingPlansAPI.getConnectedPlans(channelId)];
                case 1:
                    plansIds = _a.sent();
                    handleGoToPricingPlanPicker(plansIds.map(function (plan) { return plan.id; }), channelId);
                    return [2 /*return*/];
            }
        });
    }); };
};
