var _a;
import { handleActions } from 'redux-actions';
import { NAMES as VIDEO_PLAYBACK_ACTIONS } from '../../actions/player/change-playback-status';
import { SELECTED_VIDEO_ACTIONS } from '../../actions/select-video';
import { VIDEO_PLAYBACK_STATUSES } from '@wix/wix-vod-constants/dist/common/video-playback-statuses';
var defaultState = VIDEO_PLAYBACK_STATUSES.INITIAL;
var setPlayRequestedStatus = function () { return VIDEO_PLAYBACK_STATUSES.PLAY_REQUESTED; };
var setPlayStatus = function () { return VIDEO_PLAYBACK_STATUSES.PLAYING; };
var setPlayAbortedStatus = function () { return VIDEO_PLAYBACK_STATUSES.PLAY_ABORTED; };
var setPauseStatus = function (state) {
    // fix toggling pause for player
    // if video is switched, we are reset playback status, but old player did this too
    // TODO: remove after using videoPlaybackStatus at player instead of props.paused
    if (state === VIDEO_PLAYBACK_STATUSES.INITIAL) {
        return state;
    }
    return VIDEO_PLAYBACK_STATUSES.PAUSED;
};
var setPauseRequestedStatus = function () { return VIDEO_PLAYBACK_STATUSES.PAUSE_REQUESTED; };
var setEndedStatus = function () { return VIDEO_PLAYBACK_STATUSES.ENDED; };
var resetStatus = function () { return VIDEO_PLAYBACK_STATUSES.INITIAL; };
var setStatus = function (state, _a) {
    var status = _a.payload;
    return status;
};
export default handleActions((_a = {},
    _a[VIDEO_PLAYBACK_ACTIONS.PLAY] = setPlayStatus,
    _a[VIDEO_PLAYBACK_ACTIONS.REQUEST_PLAY] = setPlayRequestedStatus,
    _a[VIDEO_PLAYBACK_ACTIONS.ABORT_PLAY] = setPlayAbortedStatus,
    _a[VIDEO_PLAYBACK_ACTIONS.PAUSE] = setPauseStatus,
    _a[VIDEO_PLAYBACK_ACTIONS.REQUEST_PAUSE] = setPauseRequestedStatus,
    _a[VIDEO_PLAYBACK_ACTIONS.RESET] = resetStatus,
    _a[VIDEO_PLAYBACK_ACTIONS.END] = setEndedStatus,
    _a[VIDEO_PLAYBACK_ACTIONS.SET] = setStatus,
    _a[SELECTED_VIDEO_ACTIONS.SET] = resetStatus,
    _a[SELECTED_VIDEO_ACTIONS.RESET] = resetStatus,
    _a), defaultState);
