import { __read, __spreadArray } from "tslib";
import { openPreviewPrompt } from '../redux/client/actions/modal';
import * as viewModeSelectors from '../../widget/selectors/view-mode';
var store;
export var initNotForPreview = function (_store) {
    store = _store;
};
export var notForPreview = function (target) { return function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    if (store) {
        var state = store.getState();
        var isPreview = viewModeSelectors.isPreviewMode(state);
        var isEditor = viewModeSelectors.isEditorMode(state);
        if (isPreview || isEditor) {
            store.dispatch(openPreviewPrompt());
            return;
        }
    }
    return target.apply(void 0, __spreadArray([], __read(args), false));
}; };
