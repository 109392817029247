import { createAction } from 'redux-actions';
import { setMuted } from './sound';
import { setAutoPlayed } from './set-autoplayed';
import { isVideoPlayingOptimistic } from '../../../../selectors/video-playback-status';
import { createProxy } from '../../../../../shared/worker/lib';
export var NAMES = {
    PLAY: 'CLIENT.VIDEO.PLAYBACK_STATUS.SET_PLAY',
    REQUEST_PLAY: 'CLIENT.VIDEO.PLAYBACK_STATUS.REQUEST_PLAY',
    ABORT_PLAY: 'CLIENT.VIDEO.PLAYBACK_STATUS.ABORT_PLAY',
    PAUSE: 'CLIENT.VIDEO.PLAYBACK_STATUS.SET_PAUSE',
    REQUEST_PAUSE: 'CLIENT.VIDEO.PLAYBACK_STATUS.REQUEST_PAUSE',
    RESET: 'CLIENT.VIDEO.PLAYBACK_STATUS.RESET',
    END: 'CLIENT.VIDEO.PLAYBACK_STATUS.SET_END',
    SET: 'CLIENT.VIDEO.PLAYBACK_STATUS.SET',
};
var playVideoAction = createAction(NAMES.REQUEST_PLAY);
var pauseVideoAction = createAction(NAMES.REQUEST_PAUSE);
export var abortPlayVideo = createAction(NAMES.ABORT_PLAY);
export var resetPlaybackStatus = createAction(NAMES.RESET);
// Oh boy, don't even try to use it anywhere.
export var setPlaybackStatus = createAction(NAMES.SET);
export var playVideo = createProxy('playVideo', function (id, muted, autoPlayed) {
    if (muted === void 0) { muted = false; }
    if (autoPlayed === void 0) { autoPlayed = false; }
    return function (dispatch) {
        dispatch(setMuted(muted));
        dispatch(playVideoAction(id));
        dispatch(setAutoPlayed(autoPlayed));
    };
});
export var pauseVideo = createProxy('pauseVideo', function () { return function (dispatch, getState) {
    var state = getState();
    // When user clicked on 'show action cards' we dispatch pause action which change playback status to 'PAUSED'.
    // But if video already ended and playback status is 'ENDED' we don't need to change it to 'PAUSED', because
    // it breaks UI
    if (isVideoPlayingOptimistic(state)) {
        dispatch(pauseVideoAction());
    }
}; });
