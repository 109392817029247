var _a;
import { __assign } from "tslib";
import _ from 'lodash';
import { handleActions } from 'redux-actions';
import { CHANNEL_PAYMENT_ACTIONS, CHANNEL_PRICING_ACTIONS, } from '../../../server/actions/channel/payment.js';
var PATH_TO_CHANNELS = 'response.data.entities.channels';
var defaultState = {};
export default handleActions((_a = {},
    _a[CHANNEL_PAYMENT_ACTIONS.NAMES.SUCCESS] = function (state, _a) {
        var payload = _a.payload;
        var channels = _.get(payload, PATH_TO_CHANNELS);
        var channelPaymentData = _.values(channels)[0];
        return __assign(__assign({}, state), channelPaymentData);
    },
    _a[CHANNEL_PRICING_ACTIONS.NAMES.SUCCESS] = function (state, _a) {
        var hasAccess = _a.payload.params.hasAccess;
        return (__assign(__assign({}, state), { hasAccessToChannelByPricingPlan: hasAccess }));
    },
    _a), defaultState);
