var _a;
import { logBi } from '../../../../../shared/worker/actions/bi';
import { getAllSettings } from '../../../../../shared/selectors/app-settings';
import { getChannelForWidget } from '@wix/wix-vod-shared/dist/src/common/selectors/channels';
import { getMainVideoId } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/selected-video-id';
import { getVideosGroupedByIds } from '../../../../../shared/selectors/videos';
import { getVideoSelectedEventParams } from '../../../../../shared/utils/bi/video-overlay-events-params';
import { SELECTED_VIDEO_ACTIONS } from '../../../client/actions/select-video';
export default (_a = {},
    _a[SELECTED_VIDEO_ACTIONS.SET] = function (_a) {
        var dispatch = _a.dispatch, state = _a.state, action = _a.action, previousState = _a.previousState;
        if (action.payload === previousState.selectedVideoId) {
            return;
        }
        dispatch(logBi('widget.VidSelected.displayed', getVideoSelectedEventParams({
            appSettings: getAllSettings(state),
            channelData: getChannelForWidget(state),
            videoItem: getVideosGroupedByIds(state)[getMainVideoId(state)],
        })));
    },
    _a);
