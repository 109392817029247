export var createPerformanceHandler = function (_a) {
    var performanceLogger = _a.performanceLogger;
    var called = false;
    return function () {
        if (!called) {
            performanceLogger.appLoaded();
            called = true;
        }
    };
};
