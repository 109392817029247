var _a;
import { __assign } from "tslib";
import { handleActions, createAction } from 'redux-actions';
import { VIDEO_PLAYBACK_STATUSES } from '@wix/wix-vod-constants/dist/common/video-playback-statuses';
import { selectVideo } from '../../../../widget/redux/client/actions/select-video';
import { createProxy } from '../../../worker/lib';
export var NAMES = {
    ABORT_PLAY: 'CLIENT.PLAYBACK.ABORT_PLAY',
    REQUEST_PLAY: 'CLIENT.PLAYBACK.REQUEST_PLAY',
    REQUEST_PAUSE: 'CLIENT.PLAYBACK.REQUEST_PAUSE',
    SET_PLAYING: 'CLIENT.PLAYBACK.SET_PLAYING',
    SET_PAUSED: 'CLIENT.PLAYBACK.SET_PAUSED',
    SET_ENDED: 'CLIENT.PLAYBACK.SET_ENDED',
    SET_MUTED: 'CLIENT.PLAYBACK.SET_MUTED',
    SET_INITIAL: 'CLIENT.PLAYBACK.SET_INITIAL',
};
var INITIAL = VIDEO_PLAYBACK_STATUSES.INITIAL, PLAY_ABORTED = VIDEO_PLAYBACK_STATUSES.PLAY_ABORTED, PLAY_REQUESTED = VIDEO_PLAYBACK_STATUSES.PLAY_REQUESTED, PAUSE_REQUESTED = VIDEO_PLAYBACK_STATUSES.PAUSE_REQUESTED, PLAYING = VIDEO_PLAYBACK_STATUSES.PLAYING, PAUSED = VIDEO_PLAYBACK_STATUSES.PAUSED, ENDED = VIDEO_PLAYBACK_STATUSES.ENDED;
var defaultState = {};
var requestPlayAction = createAction(NAMES.REQUEST_PLAY);
export var abortPlay = createAction(NAMES.ABORT_PLAY);
export var requestPause = createAction(NAMES.REQUEST_PAUSE);
export var setInitial = createAction(NAMES.SET_INITIAL);
export var setPlaying = createAction(NAMES.SET_PLAYING);
export var setPaused = createAction(NAMES.SET_PAUSED);
export var setEnded = createAction(NAMES.SET_ENDED);
export var setMuted = createAction(NAMES.SET_MUTED);
export var requestPlay = createProxy('requestPlay', function (id, muted) {
    if (muted === void 0) { muted = false; }
    return function (dispatch) {
        dispatch(selectVideo(id));
        dispatch(setMuted(muted));
        dispatch(requestPlayAction(id));
    };
});
var setStatusHandler = function (status) { return function (state, _a) {
    var _b;
    var id = _a.payload;
    var playerState = state[id];
    return __assign(__assign({}, state), (_b = {}, _b[id] = __assign(__assign({}, playerState), { status: status }), _b));
}; };
var setMutedHandler = function (state, _a) {
    var muted = _a.payload;
    return (__assign(__assign({}, state), { muted: muted }));
};
export default handleActions((_a = {},
    _a[NAMES.ABORT_PLAY] = setStatusHandler(PLAY_ABORTED),
    _a[NAMES.REQUEST_PLAY] = setStatusHandler(PLAY_REQUESTED),
    _a[NAMES.REQUEST_PAUSE] = setStatusHandler(PAUSE_REQUESTED),
    _a[NAMES.SET_INITIAL] = setStatusHandler(INITIAL),
    _a[NAMES.SET_PLAYING] = setStatusHandler(PLAYING),
    _a[NAMES.SET_PAUSED] = setStatusHandler(PAUSED),
    _a[NAMES.SET_ENDED] = setStatusHandler(ENDED),
    _a[NAMES.SET_MUTED] = setMutedHandler,
    _a), defaultState);
