import { __assign } from "tslib";
import { VIEW_MODES } from '@wix/wix-vod-constants/dist/common/view-modes';
import { getAppLocale } from '../../../../services/locale/locale';
var asHttpResponse = function (data) { return (data ? { data: data } : null); };
export var parseHydratedData = function (source) {
    var currentPageId = source.currentPageId, siteOwnerId = source.siteOwnerId, fullSiteUrl = source.fullSiteUrl, templateMetaSiteId = source.templateMetaSiteId, metaSiteId = source.metaSiteId, viewMode = source.viewMode;
    var translations = source.__TRANSLATIONS__;
    var currentSiteUser = source.__CURRENT_SITE_USER__;
    var locale = source.__LOCALE__ ||
        getAppLocale({
            appSettings: source.appSettings,
            siteLocale: source.siteLocale,
        });
    return __assign({ biToken: source.biToken, experiments: source.__EXPERIMENTS__, windowSize: source.windowSize, metaSiteId: metaSiteId, locale: locale, translations: translations, siteUrl: source.__SITE_URL__, staticsVersion: source.staticsVersion || __CI_APP_VERSION__, editorType: source.__EDITOR_TYPE__, instance: source.instance, instanceId: source.instanceId, viewMode: viewMode, compId: source.compId, currentSiteUser: viewMode === VIEW_MODES.SITE ? currentSiteUser : null, deviceType: source.deviceType, templateMetaSiteId: templateMetaSiteId, currentPageId: currentPageId, siteOwnerId: siteOwnerId, fullSiteUrl: fullSiteUrl }, parseWidgetData(source.widgetData || {}));
};
var parseWidgetData = function (widgetData) {
    var _a;
    if (widgetData.isV3Api) {
        return {
            channel: {
                data: __assign(__assign({}, widgetData.__CHANNEL__), { deal_info: widgetData.__CHANNEL__.dealInfo }),
            },
            channelVideos: asHttpResponse(widgetData.videos),
            singleVideo: asHttpResponse(widgetData.singleVideo),
            singleVideoId: (_a = widgetData.singleVideo) === null || _a === void 0 ? void 0 : _a.id,
        };
    }
    return parseOldWidgetData(widgetData);
};
var parseOldWidgetData = function (widgetData) {
    var singleVideo = widgetData.__SINGLE_VIDEO__;
    var channelVideos = widgetData.__VIDEOS__;
    var memberInfo = widgetData.__MEMBER_INFO__;
    var channel = widgetData.__CHANNEL__;
    return {
        channel: asHttpResponse(channel),
        channelVideos: asHttpResponse(channelVideos),
        memberInfo: asHttpResponse(memberInfo),
        singleVideo: asHttpResponse(singleVideo),
        singleVideoId: singleVideo && singleVideo.item_id,
    };
};
