import _ from 'lodash';
import { keysToCamelCase } from '@wix/wix-vod-shared/dist/src/common/utils/object';
function TransportError(status, url, error) {
    this.status = status;
    this.error = error;
    this.url = url;
    this.name = this.constructor.name;
}
TransportError.prototype = _.create(Error.prototype);
function getUrl(response) {
    var urlFromWixMP = _.get(response, 'config.url');
    return urlFromWixMP || response.url;
}
export function toError(response) {
    if (response === void 0) { response = {}; }
    response = response.response || response;
    if (response instanceof TransportError) {
        return Promise.reject(response);
    }
    var code = response.code, status = response.status, _a = response.data, data = _a === void 0 ? {} : _a, message = response.message;
    var url = getUrl(response);
    var dataWithCamelCase = keysToCamelCase(data);
    var errorCode = dataWithCamelCase.errorCode, _b = dataWithCamelCase.errorDescription, errorDescription = _b === void 0 ? message : _b;
    return Promise.reject(new TransportError(status || code, url, {
        code: errorCode,
        data: dataWithCamelCase,
        message: errorDescription,
    }));
}
