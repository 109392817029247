var _a;
import { __assign } from "tslib";
import { handleActions } from 'redux-actions';
import SEARCH_QUERY_FIELDS from '../../../../shared/constants/search-query-fields';
import { NAMES as CHANNEL_VIDEOS_LIST } from '../../../../shared/redux/server/actions/channel/videos/list-public';
import { SEARCH_ACTIONS } from '../actions/search';
var DEFAULT_STATE = {
    searchQuery: '',
    searchQueryField: SEARCH_QUERY_FIELDS.QUERY,
    isSearching: false,
};
var updateSearch = function (state, _a) {
    var payload = _a.payload;
    var searchQuery = payload.searchQuery, searchQueryField = payload.searchQueryField;
    return __assign(__assign({}, state), { searchQuery: searchQuery, searchQueryField: searchQueryField, isSearching: searchQuery !== state.searchQuery ||
            searchQueryField !== state.searchQueryField });
};
var markSearchComplete = function (state) { return (__assign(__assign({}, state), { isSearching: false })); };
export default handleActions((_a = {},
    _a[SEARCH_ACTIONS.UPDATE_SEARCH] = updateSearch,
    _a[SEARCH_ACTIONS.RESET_SEARCH] = function () { return DEFAULT_STATE; },
    _a[CHANNEL_VIDEOS_LIST.SUCCESS] = markSearchComplete,
    _a[CHANNEL_VIDEOS_LIST.FAIL] = markSearchComplete,
    _a), DEFAULT_STATE);
