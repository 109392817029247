import { __assign, __awaiter, __generator } from "tslib";
import { createAction } from 'redux-actions';
import { getHydratedData } from '../hydrated-data/hydrated-data';
import { updateWidgetSingleVideo } from '../../initial-data-desktop';
export var APP_SETTINGS_UPDATE = 'CLIENT.APP_SETTINGS.SET';
export var setAppSettings = createAction(APP_SETTINGS_UPDATE);
export var updateAppSettings = function (styleParams) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(setAppSettings(styleParams));
                return [4 /*yield*/, dispatch(updateWidgetSingleVideo())];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); }; };
export var setInitialAppSettings = function (styleParams) { return function (dispatch, getState) {
    var state = getState();
    var _a = getHydratedData(state), siteUrl = _a.siteUrl, locale = _a.locale;
    var settings = __assign(__assign({}, styleParams), { siteUrl: siteUrl, locale: locale });
    dispatch(setAppSettings(settings));
}; };
