import { __assign } from "tslib";
import _ from 'lodash';
import { createAction } from 'redux-actions';
import { getSearchQuery, getSearchQueryField, } from '../../../../shared/selectors/search';
import { isSingleVideo, isStripLayout, isVideoListAlwaysShow, } from '../../../../shared/selectors/app-settings';
import { getVideosPerPageCount, getCursor, getVideoIdsByPageNumber, getVideoIds, getCurrentPageIndex, } from './selectors';
import { listPublicChannelVideos } from '../../../../shared/redux/server/actions/channel/videos/list-public';
import { createProxy, createAsyncProxy } from '../../../../shared/worker/lib';
import { getCurrentChannelId } from '../../../selectors/channel-info';
export var RESET_LAZY_CHANNEL_VIDEOS = 'CLIENT.LAZY_CHANNEL_VIDEOS.RESET';
export var SET_LAZY_CHANNEL_VIDEOS_PAGE_INDEX = 'CLIENT.LAZY_CHANNEL_VIDEOS.PAGE_INDEX.SET';
export var SET_LAZY_CHANNEL_VIDEOS_VIDEO_INDEX = 'CLIENT.LAZY_CHANNEL_VIDEOS.VIDEO_INDEX.SET';
export var PREPEND_LAZY_VIDEO_IDS = 'CLIENT.LAZY_CHANNEL_VIDEOS.PREPEND_VIDEO_IDS';
export var setLazyChannelVideosPageIndex = createAction(SET_LAZY_CHANNEL_VIDEOS_PAGE_INDEX);
export var setLazyChannelVideosVideoIndex = createAction(SET_LAZY_CHANNEL_VIDEOS_VIDEO_INDEX);
export var resetLazyChannelVideos = createAction(RESET_LAZY_CHANNEL_VIDEOS);
export var prependVideoIds = createAction(PREPEND_LAZY_VIDEO_IDS);
var PAGES_PER_REQUEST_COUNT = 1;
export var loadMore = createAsyncProxy('loadMore', function (loadMoreNumber, _a) {
    var _b = _a === void 0 ? {} : _a, category = _b.category, tag = _b.tag, query = _b.query;
    return function (dispatch, getState, _a) {
        var storage = _a.storage;
        var state = getState();
        var searchParams = _.omitBy({ query: query, tag: tag, category: category }, _.isEmpty);
        storage.loadingPromise = (storage.loadingPromise || Promise.resolve()).then(function () {
            var cursor = getCursor(state);
            if (cursor && storage.lastCursor === cursor) {
                return Promise.resolve();
            }
            storage.lastCursor = cursor;
            return dispatch(listPublicChannelVideos(getCurrentChannelId(state), __assign({ paging: {
                    size: loadMoreNumber,
                    cursor: cursor,
                } }, searchParams)));
        });
        return storage.loadingPromise;
    };
});
export var loadMoreVideoPages = createAsyncProxy('loadMoreVideoPages', function (pagesCount) {
    if (pagesCount === void 0) { pagesCount = PAGES_PER_REQUEST_COUNT; }
    return function (dispatch, getState) {
        var _a;
        var state = getState();
        if (isSingleVideo(state)) {
            return Promise.resolve();
        }
        var searchQuery = getSearchQuery(state);
        var searchQueryField = getSearchQueryField(state);
        var videosPerPage = getVideosPerPageCount(state);
        if (isStripLayout(state) && isVideoListAlwaysShow(state)) {
            pagesCount = 2;
        }
        return dispatch(loadMore(videosPerPage * pagesCount, (_a = {}, _a[searchQueryField] = searchQuery, _a)));
    };
});
export var goToLazyVideosPageIndex = createProxy('goToLazyVideosPageIndex', function (pageIndex) { return function (dispatch, getState) {
    var state = getState();
    var videoIdsByPageNumber = getVideoIdsByPageNumber(state);
    var cursor = getCursor(state);
    if (videoIdsByPageNumber.length === pageIndex + 1 && cursor) {
        dispatch(loadMoreVideoPages());
    }
    dispatch(setLazyChannelVideosPageIndex(pageIndex));
}; });
export var loadMoreVideosForMobileVertical = createProxy('loadMoreVideosForMobileVertical', function () { return function (dispatch, getState) {
    var state = getState();
    var cursor = getCursor(state);
    var currentPageIndex = getCurrentPageIndex(state);
    if (cursor) {
        dispatch(loadMoreVideoPages(1));
    }
    dispatch(setLazyChannelVideosPageIndex(currentPageIndex + 1));
}; });
export var goToLazyVideoIndex = function (videoIndex) { return function (dispatch, getState) {
    var state = getState();
    var videoIds = getVideoIds(state);
    var cursor = getCursor(state);
    if (videoIds.length === videoIndex + 1 && cursor) {
        dispatch(loadMoreVideoPages());
    }
    dispatch(setLazyChannelVideosVideoIndex(videoIndex));
}; };
