import { __assign } from "tslib";
import createAsyncActions from '../../../../../shared/redux/helpers/create-async-actions';
import { normalizeChannel } from '../../../../../shared/redux/helpers/normalized';
import { getPublic as getChannelPublic } from '../../../../../api/public/channel/channel';
import { getHydratedData } from '../../../client/hydrated-data/hydrated-data';
import { createAsyncProxy } from '../../../../../shared/worker/lib';
import { isGraphAPIEnabled } from '@wix/wix-vod-shared/dist/src/common/utils/experiments';
import { publicApiV3 } from '../../../../../api/v3/public';
var ACTIONS = createAsyncActions('SERVER.CHANNEL.GET');
export var NAMES = ACTIONS.NAMES;
var START = ACTIONS.START, SUCCESS = ACTIONS.SUCCESS, FAIL = ACTIONS.FAIL;
export var getPublicChannel = createAsyncProxy('channel.getPublic', function (id) { return function (dispatch, getState) {
    var params = { id: id };
    var state = getState();
    var _a = getHydratedData(state), hydratedChannel = _a.channel, templateMetaSiteId = _a.templateMetaSiteId;
    dispatch(START(params));
    var promise = isGraphAPIEnabled()
        ? hydratedChannel
            ? Promise.resolve(hydratedChannel)
            : publicApiV3.channel.getChannel(id)
        : getChannelPublic(id, {
            hydratedChannel: hydratedChannel,
            templateMetaSiteId: templateMetaSiteId,
        });
    return promise.then(function (response) {
        var data = response.data;
        response = __assign(__assign({}, response), { data: normalizeChannel(data) });
        dispatch(SUCCESS(params, response));
        return response;
    }, function (response) {
        dispatch(FAIL(params, response, {
            analytics: { type: 'error', name: 'shared.channel.video.get' },
        }));
    });
}; });
