import { VIDEO_PLAYBACK_STATUSES } from '@wix/wix-vod-constants/dist/common/video-playback-statuses';
export var isVideoPlaying = function (state) {
    return state.player.playback === VIDEO_PLAYBACK_STATUSES.PLAYING;
};
export var isVideoInInitialState = function (state) {
    return state.player.playback === VIDEO_PLAYBACK_STATUSES.INITIAL;
};
export var isVideoPaused = function (state) {
    return state.player.playback === VIDEO_PLAYBACK_STATUSES.PAUSED;
};
export var isVideoPlayRequested = function (state) {
    return state.player.playback === VIDEO_PLAYBACK_STATUSES.PLAY_REQUESTED;
};
export var isVideoPauseRequested = function (state) {
    return state.player.playback === VIDEO_PLAYBACK_STATUSES.PAUSE_REQUESTED;
};
export var isVideoEnded = function (state) {
    return state.player.playback === VIDEO_PLAYBACK_STATUSES.ENDED;
};
export var isVideoPlayingOptimistic = function (state) {
    return isVideoPlaying(state) || isVideoPlayRequested(state);
};
export var isVideoPausedOptimistic = function (state) {
    return isVideoPaused(state) || isVideoPauseRequested(state);
};
export var isVideoPlayAborted = function (state) {
    return state.player.playback === VIDEO_PLAYBACK_STATUSES.PLAY_ABORTED;
};
