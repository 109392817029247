var _a;
import { __assign } from "tslib";
import { handleActions } from 'redux-actions';
import { NAMES as GET_VIDEO_CARDS } from '../../../server/actions/video-cards/get';
import { VIDEO_CARDS_CLEANUP } from '../../actions/video-cards/cleanup';
import { getResizedImageUrl } from '@wix/wix-vod-shared/dist/src/common/utils/get-resized-image-url';
import { VIDEO_CARD_TYPES } from '@wix/wix-vod-constants/dist/video-cards/types';
import i18n from '@wix/wix-vod-shared/dist/src/common/i18n';
import { ItemType } from '@wix/wix-vod-constants/dist/api-types/enums/item-type';
var defaultState = [];
var THUMBNAIL_SIZE = 292;
export default handleActions((_a = {},
    _a[GET_VIDEO_CARDS.SUCCESS] = function (cards, _a) {
        var payload = _a.payload;
        var data = payload.response;
        return data.map(function (card) {
            if (card.dateStartLive) {
                var datetime = new Date(card.dateStartLive).toLocaleTimeString([], {
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: '2-digit',
                });
                card.liveLabel = "".concat(i18n.t('widget.video-cards.live-label'), ": ").concat(datetime);
            }
            if (card.type === VIDEO_CARD_TYPES.VIDEO ||
                card.type === VIDEO_CARD_TYPES.RECENT_VIDEO) {
                if (card.itemType === ItemType.ItemLive) {
                    card.buttonLabel = i18n.t('widget.video-cards.watch-live');
                }
                else {
                    card.buttonLabel = i18n.t('widget.video-cards.watch-next');
                }
            }
            if (card.type === VIDEO_CARD_TYPES.CHANNEL) {
                card.buttonLabel = i18n.t('widget.video-cards.watch-more');
            }
            return __assign(__assign({}, card), { thumbnailUrl: getResizedImageUrl({
                    url: card.thumbnailUrl,
                    width: THUMBNAIL_SIZE,
                    height: THUMBNAIL_SIZE,
                    params: { lg: 1 },
                }) });
        });
    },
    _a[VIDEO_CARDS_CLEANUP] = function () { return defaultState; },
    _a), defaultState);
