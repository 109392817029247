var _a;
import { __assign } from "tslib";
import { handleActions } from 'redux-actions';
import { NAMES } from '../actions/modules';
var defaultState = {
    playbackModuleLoaded: false,
};
export default handleActions((_a = {},
    _a[NAMES.PLAYBACK_MODULE_LOADED] = function (state) { return (__assign(__assign({}, state), { playbackModuleLoaded: true })); },
    _a), defaultState);
