import { createSelector } from 'reselect';
import { VIEW_MODES } from '@wix/wix-vod-constants/dist/common/view-modes';
import { getHydratedData } from '../redux/client/hydrated-data/hydrated-data';
export var getViewMode = createSelector(getHydratedData, function (data) { return data.viewMode; });
var isMatchingViewMode = function (expectedViewMode) {
    return createSelector(getViewMode, function (viewMode) { return viewMode === expectedViewMode; });
};
export var isPreviewMode = isMatchingViewMode(VIEW_MODES.PREVIEW);
export var isEditorMode = isMatchingViewMode(VIEW_MODES.EDITOR);
export var isSiteMode = isMatchingViewMode(VIEW_MODES.SITE);
export var isStandalone = isMatchingViewMode(VIEW_MODES.STANDALONE);
