import { createAction } from 'redux-actions';
import { createProxy } from '../../../../shared/worker/lib';
export var SELECTED_VIDEO_ACTIONS = {
    SET: 'CLIENT.SELECTED_VIDEO.SET',
    RESET: 'CLIENT.SELECTED_VIDEO.RESET',
};
var selectVideoAction = createAction(SELECTED_VIDEO_ACTIONS.SET);
export var selectVideo = createProxy('selectVideo', function (videoId) { return function (dispatch, getState) {
    var selectedVideoId = getState().selectedVideoId;
    if (selectedVideoId !== videoId) {
        dispatch(selectVideoAction(videoId));
    }
}; });
export var resetSelectedVideo = createAction(SELECTED_VIDEO_ACTIONS.RESET);
